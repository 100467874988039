<template>
  <div class="info">
    <top-bar :title="'修改信息'" :left="true"/>

    <van-popup v-model="sexShow" round position="bottom">
      <van-picker  show-toolbar  :columns="sexList"  @cancel="sexShow = false"  @confirm="sexChange"/>
    </van-popup>
    <van-calendar
    v-model="dateShow"
    color="#1989fa"
    :show-title="false"
    :show-confirm="false"
    @select="birthdayChange"
    :default-date="new Date(...birthday)"
    :min-date="new Date('1900','1','1')"
    :max-date="new Date()"/>

    <div class="headbox">
      <div class="Avatar">
        <img :src="myDetailInfo.headImg" alt="">
        <div class="headImgbtn">
          <van-uploader :after-read="uploadRead">
          <van-button plain size="small">上传头像</van-button>
        </van-uploader>
        </div>
      </div>
    </div>
    <div class="">
      <van-cell-group>
        <van-cell   >
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">居民信息</span>
          </template>
        </van-cell>
        <van-field  v-model="myDetailInfo.name"  label="文本"  placeholder="未知"  input-align="right"/>
        <van-cell title="性别" :value="myDetailInfo.sex == 1?'男':'女'" is-link @click="sexShow = true"/>
        <van-cell title="国籍" :value="myDetailInfo.nationalityStr || '未知'" is-link @click="goNationality"/>
        <van-field  v-model="myDetailInfo.idNumber"  label="文本"  placeholder="未知"  input-align="right"/>
        <van-field  v-model="myDetailInfo.mobile"  label="文本"  placeholder="未知"  input-align="right"/>
        <van-cell title="民族" :value="myDetailInfo.nationStr || '未知'" is-link  @click="goNation"/>
        <van-cell title="出生日期" :value="myDetailInfo.birthday || '未知'" is-link @click="dateShow = true"/>
        <van-field  v-model="myDetailInfo.company"  label="工作单位"  placeholder="未知"  input-align="right"/>
        <van-field  v-model="myDetailInfo.regAddress"  label="户籍地址"  placeholder="未知"  input-align="right"/>
        <van-field  v-model="myDetailInfo.nowAddress"  label="现居地"  placeholder="未知"  input-align="right"/>
        <van-field  v-model="myDetailInfo.emContact"  label="紧急联系人"  placeholder="未知"  input-align="right"/>
        <van-field  v-model="myDetailInfo.emMobile"  label="联系人手机号"  placeholder="未知"  input-align="right"/>
        <!-- <van-field  v-model="myDetailInfo.buildingRoom"  label="隔间房号"  placeholder="未知"  input-align="right"/> -->
      </van-cell-group>
      <van-row>
        <van-col span="12">
          <van-button type="info" round block @click="onCancel">取消</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="info" round block @click="onSubmit">完成</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import TopBar from '../../components/topBar/topBar.vue'
import { mapState } from 'vuex'

import {getImageStream} from '@/utils/index'
import {formatterDate} from '@/utils/utils'
export default {
  components: {
    topBar
  },
  computed:{...mapState(['nationality','nation'])},
  data () {
    return {
      headImg: '',
      birthday:'',
      myDetailInfo: {},
      sexShow: false,
      dateShow: false,
      sexList:[{text:'男', value:'1'},{text:'女',value:'2'}]
    }
  },
  methods: {
    getDetailInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/jmggDetailInfo'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.myDetailInfo = data.userInfoEntity
          this.myDetailInfo.headImg = data.userInfoEntity.headImg ? getImageStream(data.userInfoEntity.headImg) : getImageStream('files/wx/images/content/header-default.png',process.env.VUE_APP_BASE_BACK_URL)
          if(this.nationality != 0 && typeof this.nationality == 'object') {
            this.myDetailInfo.nationalityStr = this.nationality.label
            this.myDetailInfo['nationality'] = this.nationality.value
          }
          if(this.nation != 0 && typeof this.nation == 'object') {
            this.myDetailInfo.nationStr = this.nation.label
            this.myDetailInfo['nation'] = this.nation.value
          }
          this.birthday = this.myDetailInfo.birthday.split('-')
        } else {
        this.$toast.fail(data.msg);
        }
      })
    },
    onSubmit () {
      this.$dialog.confirm({
        message: '确认提交？',
      })
      .then(() => {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/userInfo/update'),
        method: 'post',
        data: this.$http.adornData({
          birthday: this.myDetailInfo.birthday,
          company: this.myDetailInfo.company,
          emContact: this.myDetailInfo.emContact,
          emMobile: this.myDetailInfo.emMobile,
          headImg: this.headImg,
          id: this.myDetailInfo.id,
          idNumber: this.myDetailInfo.idNumber,
          mobile: this.myDetailInfo.mobile,
          name: this.myDetailInfo.name,
          nation: this.myDetailInfo.nation,
          nationality: this.myDetailInfo.nationality,
          nowAddress: this.myDetailInfo.nowAddress,
          orgId: this.$orgId,
          regAddress: this.myDetailInfo.regAddress,
          sex: this.myDetailInfo.sex,
          temporaryHeadImg: '',
          temporaryHeadImgSuffix: ''
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success('修改成功')
          this.$router.go(-2)
        } else {
        this.$toast.fail(data.msg);
        }
      })
      })
      .catch(() => {
      });
    },
    onCancel () {
      this.$router.go(-1)
    },
    birthdayChange (value) {
      this.myDetailInfo.birthday = formatterDate(value)
    },
    sexChange (value) {
      this.myDetailInfo.sex =  value.value
      this.sexShow = false
    },
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", 'files/userHeadImg');
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
      }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.headImg = data.fileMessage.relativePath
            this.myDetailInfo.headImg = getImageStream(data.fileMessage.relativePath)
          return true
          } else {
          this.$toast.fail(data.msg);
          }
        })
      })
    },
    goNation () {
      this.$router.push('/nation')
    },
    goNationality () {
      this.$router.push('/nationality')
    }
  },
  created () {
    this.getDetailInfo()
  }
}

    TopBar</script>

<style lang="scss" scoped>
.info {
  padding-top: 100px;
  background-color: #f5f5f5;
}
  .headbox {
    background-color: #fff;
    margin: 30px;
    padding: 30px;
    .Avatar {
      width: 100%;
      text-align: center;
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    .headImgbtn {
      height: 80px;
      margin-top: 30px;
      button {
        width: 180px;
        height: 70px;
      }
    }
  }
  .blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
</style>
